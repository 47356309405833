import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeTirages from "../../../composants/layout/fr/LayoutGalerieListeTirages"

import { obtenirImages, obtenirPage } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageVenteTiragesVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "venteTiragesVoieLactee" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "panoramiqueGalactique"
            "bulbeZetaOphiuchi"
            "voieLacteeIntegralite"
            "voieLacteeSourceUnivers"
            "telescopeHESSII"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { eq: "megalaxyZoom" } }
    ) {
      ...FragmentImagePortrait
    }
  }
`

export default function TiragesVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageVenteTiragesVoieLactee")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeTirages page={page} images={images} />
}
